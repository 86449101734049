import React from "react";
import Layout from "../layouts/Layout";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const options = {
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

function Impressum({ data }) {
  const pageContext = { pagePath: "/impressum" };
  return (
    <Layout pageContext={pageContext}>
      <div className="container">
        <div className="text-lg max-w-screen-lg 2xl:max-w-screen-xl mx-auto 2xl:mx-0 leading-relaxed">
          <div className="max-w-screen-md">
            <div className="text-5xl py-8 mt-6 font-medium">Impressum</div>
            {renderRichText(data.impressums.nodes[0].bodyDe, options)}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Impressum;

export const query = graphql`
  query ImpressumQuery {
    impressums: allContentfulImpressum {
      nodes {
        title
        bodyDe {
          raw
        }
      }
    }
  }
`;
